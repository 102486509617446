.default-hero-item .link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.default-hero-item .link .icon {
  font-size: 1.5rem;
  color: #FFFFFF;
}
.default-hero-item .link a {
  text-decoration: underline;
  color: #FFFFFF;
  margin-top: 0;
}