.image-hover {
  position: relative;
  overflow: hidden;
}
.image-hover .hover-content {
  opacity: 0;
  position: absolute;
  bottom: -100%;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.image-hover .hover-content h2, .image-hover .hover-content h4, .image-hover .hover-content div {
  color: #FFFFFF;
}
.image-hover:hover .hover-content {
  opacity: 1;
  transition: bottom 0.5s, opacity 0.5s linear;
  bottom: 0;
}