@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-brands-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Regular";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-regular-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot");
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}
.hero {
  width: 100%;
}
.hero .slick-dots {
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  bottom: 2em;
  padding: auto;
}
.hero .slick-dots li, .hero .slick-dots li.slick-active {
  margin: 0;
}
.hero .slick-dots li button:before, .hero .slick-dots li.slick-active button:before {
  color: #FFFFFF;
  opacity: 1;
  content: "\f111";
  font-family: "Font Awesome 5 Regular";
  font-size: 12px;
}
.hero .slick-dots li.slick-active button:before {
  font-family: "Font Awesome 5 Solid";
}