.map-popup {
  background-color: #FFFFFF;
  padding: 1rem;
  width: 400px;
  transform: translate(-50%, -100%);
  position: absolute;
  z-index: 1000;
}
.map-popup .popup-close-trigger {
  width: 100%;
  text-align: right;
  font-size: 1rem;
}
.map-popup .popup-close-trigger:hover {
  cursor: pointer;
}