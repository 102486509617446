@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.position-card {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 30px;
}
.position-card img {
  width: 100%;
  height: auto;
}
.position-card .details {
  padding: 10px;
}
.position-card .details .job-title,
.position-card .details .phone,
.position-card .details .email {
  font-size: 14px;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  line-height: 22px;
}
@media (min-width: 768px) {
  .position-card {
    font-size: 5em;
  }
}