.image-text-item {
  min-height: 400px;
  opacity: 0;
  margin-top: 3rem;
  transition: opacity 500ms ease, margin-top 500ms ease;
  position: relative;
}
.image-text-item .text.paragraph {
  padding: 3rem;
  background-color: #f0f0f0;
  height: 100%;
}
.image-text-item .text.paragraph.editor-body h1.body-large-headline {
  font-size: 3rem;
}
.image-text-item.visible {
  min-height: 0;
  opacity: 1;
  margin-top: 0;
}
.image-text-item .image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.image-text-item .waypoint {
  position: absolute;
  top: 200px;
}