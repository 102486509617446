@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.page-footer {
  text-align: center;
  padding: 3rem 0;
  font-size: 0.8em;
}
@media (min-width: 768px) {
  .page-footer .menu {
    display: flex;
    justify-content: center;
  }
}
.page-footer .menu a {
  color: #FFFFFF;
  padding: 0 1em;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .page-footer .menu a {
    border-left: 1px solid #FFFFFF;
  }
}
.page-footer .menu .menu-branch:first-child a {
  border-left: 1px solid #FFFFFF;
}
.page-footer .menu .menu-branch:first-of-type:first-child a {
  border-left: none;
}
@media (min-width: 768px) {
  .page-footer .menu .menu-branch:last-child a {
    padding-right: 0;
  }
}
.page-footer .copyright {
  align-items: center;
  color: #FFFFFF;
  margin: 0.8em 0;
}
.page-footer img {
  height: auto;
  width: 200px;
}