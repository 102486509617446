@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.location-pin:hover {
  cursor: pointer;
}
.location-pin .fa-map-pin {
  font-size: 2.5em;
  color: #41a8ce;
}
.location-pin .fa-utensils,
.location-pin .fa-parking,
.location-pin .fa-subway,
.location-pin .fa-plane,
.location-pin .fa-dollar-sign,
.location-pin .fa-dumbbell,
.location-pin .fa-shopping-cart,
.location-pin .fa-hotel,
.location-pin .fa-home,
.location-pin .fa-running,
.location-pin .fa-tag {
  font-size: 1.5em;
  color: #41a8ce;
}
.location-pin .pin-text {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 2em;
  min-width: 200px;
  padding-right: 0.5rem;
  text-align: right;
  position: absolute;
  left: -200px;
  top: 0;
}
.location-pin.secondary .pin-text {
  font-size: 1.5em;
}