.timeline-paragraph h2,
.timeline-paragraph h4 {
  text-align: center;
}
.timeline-paragraph .timeline-item-dateinner {
  background-color: #41a8ce;
}
.timeline-paragraph .entry h4 {
  text-align: left;
}
.timeline-paragraph .entry .text.paragraph {
  margin-top: 1rem;
}