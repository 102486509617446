@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.mini-menu h3 {
  text-align: center;
}
.mini-menu .links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.mini-menu .links .link {
  margin-bottom: 1.2rem;
}