.building-grid-item {
  color: #003058;
  font-size: 1.6rem;
  border: 1px solid #003058;
  padding: 1rem 2.5rem;
  text-align: center;
}
.building-grid-item a {
  color: #003058;
}
.building-grid-item h3 {
  font-size: 2rem;
}
.building-grid-item .address > div {
  font-size: 0.9rem;
  color: #252525;
}