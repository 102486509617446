.media-text {
  margin: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  padding: 3rem;
}
.media-text hr {
  border-top: 1px solid #FFFFFF;
  width: 35%;
  margin-top: 0.5rem;
}
.media-text .media,
.media-text .text {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .media-text .media:first-of-type,
.media-text .text:first-of-type {
    padding-bottom: 0;
  }
}
.media-text .text {
  font-size: 1.2rem;
}
.media-text.theme-light {
  background-color: #f0f0f0;
}
.media-text.theme-dark {
  background-color: #003058;
}
.media-text.theme-dark .text p,
.media-text.theme-dark .text span,
.media-text.theme-dark .text div,
.media-text.theme-dark .text h1,
.media-text.theme-dark .text h2,
.media-text.theme-dark .text h3,
.media-text.theme-dark .text h4,
.media-text.theme-dark .text h5,
.media-text.theme-dark .text h6,
.media-text.theme-dark .text blockquote,
.media-text.theme-dark .text ul,
.media-text.theme-dark .text ol,
.media-text.theme-dark .text li {
  color: #FFFFFF;
}