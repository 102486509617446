@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
p, input, label, select, span, div, html, body {
  color: #003058;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
}
p.light, input.light, label.light, select.light, span.light, div.light, html.light, body.light {
  color: #FFFFFF;
}

h1, h2, h3, h4, h5, h6 {
  color: #003058;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}

a {
  color: #41a8ce;
}
a.light {
  color: #FFFFFF;
}
a:hover {
  text-decoration: none;
  color: #3ad8ff;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.5em;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-size: 32px;
  line-height: 38px;
}

h3 {
  font-size: 28px;
  line-height: 33px;
}

.accordion-item {
  margin-bottom: 30px;
}
.accordion-item h3 {
  font-size: 19.2px;
  line-height: 28.8px;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
}
.accordion-item h3:hover {
  cursor: pointer;
}