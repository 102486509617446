@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.text.paragraph img {
  max-width: 100%;
  width: auto;
}
.text.paragraph .button {
  margin-top: 1rem;
}
.text.paragraph blockquote {
  font-size: 2rem;
  margin: 0 5em;
}
.text.paragraph blockquote p {
  margin-top: 1.5em;
  line-height: 3;
  color: #41a8ce;
}
@media (max-width: 800px) {
  .text.paragraph blockquote {
    font-size: 1.1rem;
    margin: 0 3em;
  }
  .text.paragraph blockquote p {
    line-height: 2;
  }
}
.text.paragraph table {
  font-size: 1.5rem;
  border: 2px solid #f0f0f0;
  width: 100% !important;
}
.text.paragraph table td,
.text.paragraph table th {
  border: none;
  padding: 1.5rem 2rem;
}
.text.paragraph table td p,
.text.paragraph table th p {
  font-size: inherit;
}
.text.paragraph table th {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  background-color: #f0f0f0;
}
.text.paragraph table tr:nth-child(even) {
  background-color: #f0f0f0;
}