.building-fact-grid .btn {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.building-fact-grid .btn:first-of-type {
  margin-left: 0;
}
.building-fact-grid .buttons {
  text-align: center;
  margin-top: 2rem;
}