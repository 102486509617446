.popover-menu {
  display: inline-block;
  text-align: right;
  padding: 2rem;
}
.popover-menu .trigger {
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}
.popover-menu .trigger:hover {
  cursor: pointer;
}
.popover-menu .trigger .icon {
  color: #FFFFFF;
  font-size: 2rem;
}
.popover-menu .trigger svg {
  transition: all 0.15s ease-in-out;
}
.popover-menu .links {
  text-align: left;
}
.popover-menu .links a {
  text-transform: uppercase;
}
.popover-menu .links .link {
  margin-top: 1rem;
}
.popover-menu.open {
  background-color: #FFFFFF;
}
.popover-menu.open .trigger {
  color: #013e7d;
}
.popover-menu.open .trigger .icon {
  color: #013e7d;
}
.popover-menu.open .trigger svg {
  transform: rotate(90deg);
}